import React from "react";
import authImg1 from "../../images/testimonial-placeholder-1.png";

function Our_Partner() {
  return (
    <>
      <section className="our_partner">
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-12 col-lg-12 text-center">
            <h2 className="title" style={{ color: " #202225" }}>
              OUR PARTNERS
            </h2>
          </div>
        </div>
        <div className="row justify-content-center my-5">
          <div className="col-sm-12 col-md-12 col-lg-3 mx-3 text-center">
            <h3>Kindness Partners</h3>
            <img
              className="partner_client"
              src={authImg1}
              alt="Kindness Partners"
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-3 mx-3 text-center">
            <h3>Media Partners</h3>
            <img
              className="partner_client"
              src={authImg1}
              alt="Media Partners"
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-3 mx-3 text-center">
            <h3>Education Partners</h3>
            <img
              className="partner_client"
              src={authImg1}
              alt="Education Partners"
            />
          </div>
        </div>
        <div
          className="row justify-content-center"
          style={{ margin: "0px 120px" }}
        >
          <div className="col-sm-12 col-md-12 col-lg-12 text-center">
            <p>
              **Heartfulness and UNESCO MGIEP the joint organizers of this event
              are GDPR compliant and will not be using your personal data for
              any other purpose than that of providing access to the Rising in
              Kindness - India Youth Conference**
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Our_Partner;
