const AccordionData = [
  {
    id: 1,
    qn: "Is there a registration fee?",
    ans: "Rising in Kindness is a free event; there is no registration fee. All a",
  },
  {
    id: 2,
    qn: "What are the minimum and maximum age limits to attend this event? ",
    ans: "You must be over 16 and under 35 to attend the event. ",
  },
  {
    id: 3,
    qn: "Where is Kanha Shantivanam located? What is it? ",
    ans: `Kanha Shantivanam is a 1400-acre campus located 50 kilometres from Hyderabad, India, near Chegur Village in Ranga Reddy district.

        Kanha Shanti Vanam is the world headquarters of the Heartfulness Institute. The ashram is an ecological paradise, a place for spiritual retreat, where you can experience simple living in-tune with inner and outer nature. Kanha Shanti Vanam offers spiritual training along with interactive programs that promote overall wellness and opportunity to learn and contribute. In 2019, Kanha was awarded ‘The Pride of Telangana’, for inner wellness, by Round Table India & Times of India. The center also received the Indian Green Building Council (IGBC) Platinum award in 2019 for following world class environment friendly practices becoming the first meditation center in the world to achieve the feat.
        `,
  },
  {
    id: 4,
    qn: "How do I reach the venue? ",
    ans: "Kanha Shantivanam is a 30 minute drive from Rajiv Gandhi International Airport in Hyderabad. Please review transportation options by air, train, bus, and by road here. ",
  },
  {
    id: 5,
    qn: "I won’t be able to attend this event in person. Can I watch the sessions online? ",
    ans: "If selected to attend the event, you can choose to participate virtually. We will send viewing links to the email address you register with closer to the event. ",
  },
  {
    id: 6,
    qn: "If I attend in-person, what do I need to pack for the event? ",
    ans: `The temperature in Kanha will be between 23-34℃, with some expected rain and win on some days. Please prepare for this weather, including suitable footwear and clothing.
        Doctors and a medical center are located in Kanha in case of a need for medical attention; however, we advise you to carry your own medications.
        Face masks are compulsory. Please carry a sufficient number of masks for the three days of the conference. Disposable masks are encouraged.
        Please carry a pen and a diary.
        `,
  },
];
export default AccordionData;
