import React from "react";
// import { StaticImage } from "gatsby-plugin-image";
// import { vediolink } from "../../assets/vedios/vedio.mp4"
// import ReactPlayer from 'react-player';
import "video-react/dist/video-react.css";
// import { Player } from 'video-react';
import vedio from "../../images/vedio.mp4";
import bgImage from "../../images/banner.png";

function Banner() {
  return (
    <>
      {/* <div className='container'> */}
      {/* <div className="container"> */}
      <section className="banner">
        <div className="vedio-container">
          <div className="flex-row">
            <video
              id="background-video"
              playsInline="playsinline"
              preload="auto"
              poster="../../images/banner.png"
              autoPlay
              loop
              muted
            >
              <source src={vedio} type="video/mp4" />
            </video>
            <div className="card-img-overlay">
              <img src={bgImage} alt="Youth Conference" />
            </div>
          </div>
        </div>
      </section>
      {/* </div> */}
    </>
  );
}

export default Banner;
