/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
// import { useStaticQuery, graphql } from "gatsby"

import Header from "./header";
import "./layout.css";
// import "bootstrap/dist/css/bootstrap.min.css"
// import Banner from "./Banner"

const Layout = ({ children }) => {
  return (
    <>
      <div id="wrapper">
        <Header />
        <main role="main">{children}</main>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
