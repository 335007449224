import * as React from "react";
// import { Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
// import Header from "../components/header"
import Banner from "../components/sections/Banner";
// import Get_Inspired from "../components/sections/Get_Inspired";
import GetAgenda from "../components/sections/Get_Agenda";
import ApplyNow from "../components/sections/ApplyNow";
import FAQ from "../components/sections/FAQ";
import OurPartner from "../components/sections/Our_Partner";

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Banner />
    <ApplyNow />
    <GetAgenda />
    <FAQ />
    <OurPartner />
  </Layout>
);

export default IndexPage;
