import React, { useState } from "react";

import { useForm } from "react-hook-form";
// import "font-awesome/css/font-awesome.min.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {Modal, Button} from 'react-bootstrap/Modal'
// import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
// import { faXmark } from "@fortawesome/free-solid-svg-icons";
// import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
// import { Modal, Button } from "react-bootstrap";
import ReactModal from "react-modal";
// import Link from "gatsby";

function ApplyNow() {
  const [show, setShow] = useState(false);

  const [age, setAge] = useState([]);
  const [display, setDisplay] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { register, handleSubmit, reset } = useForm();
  const [checked, setChecked] = useState(false);

  // const handleChange = () => {
  //   setChecked(!checked);
  // };

  const onSubmit = () => {
    // console.log(data, e);
    setDisplay(false);
    reset({ fullname: "", institution: "", age: "", email: "", phone: "" });
  };
  const handleChange = (e) => {
    setAge(e.target.value);
    setChecked(!checked);
  };
  return (
    <>
      <section className="apply_now">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-12 text-center">
              <h2 className="title" style={{ color: " #202225" }}>
                join the kindness movement
              </h2>
            </div>
          </div>
          <div className="container">
            <div className="row-flex justify-content-center">
              <div className="col-sm-12 col-md-12 col-lg-10 text-center">
                <div
                  className="row justify-content-left description"
                  style={{
                    marginTop: "70px",
                    width: "100%",
                    display: "inline-block",
                    height: "auto",
                  }}
                >
                  <p>
                    <span className="text_color">
                      <b>&apos;Rising with Kindness&apos; </b>
                    </span>
                    is an opportunity to celebrate moments of kindness, to
                    continue to foster
                    <br />
                    camaraderie, and to get creative with how we
                    &apos;self-care&apos;. Join us for an
                    <span className="text_color">
                      <b>
                        immersive conference
                        <br />
                        experience
                      </b>
                    </span>
                    in Kanha Shanti Vanam, the world headquarters of <br /> the
                    Heartfulness Institute in Hyderabad, India, where we will :
                    <br />
                    <li>Spotlight moments of kindness</li>
                    <li>
                      Build community with like-minded people from around India
                    </li>
                    <li>
                      Connect with our inner self through meditation, yoga, and
                      a myriad of
                      <br />
                      other wellness practices that can help all of us feel more
                      grounded.
                    </li>
                  </p>
                </div>
              </div>
            </div>
            {/* <div className='row justify-content-left'>
                        <div className='col-sm-12 col-md-12 col-lg-6 text-center'> */}
            <div className="row register_sec">
              <div
                className="col-sm-3 col-md-4 col-lg-12 register"
                style={{ display: "flex", alignItems: "center" }}
              >
                <button
                  type="submit"
                  data-toggle="modal"
                  onClick={handleShow}
                  className="apply text-center"
                  style={{
                    width: "300px",
                    height: "57px",
                    borderRadius: "62px",
                    marginBottom: "50px",
                    marginTop: "50px",
                  }}
                >
                  Register
                </button>
                <div className="container" style={{ display: "inline-flex" }}>
                  <a href="/" rel="noreferrer">
                    <p>Download brochure</p>
                  </a>
                </div>
              </div>
              <ReactModal
                isOpen={show}
                className="register_modal"
                style={{
                  content: {
                    position: "absolute",
                    marginTop: "40px",
                    left: "40px",
                    right: "40px",
                    bottom: "40px",
                    color: "#c4d490",
                  },
                }}
              >
                <button
                  type="submit"
                  onClick={handleClose}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    outline: "none",
                    color: "#a1c5cb",
                    float: "right",
                    padding: "20px",
                    position: "sticky",
                  }}
                >
                  X
                </button>
                {display ? (
                  <>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div
                        className="row"
                        style={{ marginTop: "50px", marginLeft: "40px" }}
                      >
                        <div
                          className="col-sm-12 col-md-8 col-lg-7 text-left"
                          style={{ display: "block", marginRight: "30px" }}
                        >
                          <div className="inputContainer form-group">
                            <label
                              htmlFor="fullname"
                              className="col-form-label"
                            >
                              <input
                                type="text"
                                placeholder="Full Name*"
                                name="fullname"
                                {...register("fullname")}
                                style={{
                                  backgroundColor: "#1C6455",
                                  border: "none",
                                  outline: "none",
                                  color: "rgb(196, 212, 144)",
                                }}
                              />
                            </label>
                          </div>
                          <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-8 col-lg-6 text-left">
                              <div className="inputContainer form-group">
                                <label htmlFor="age" className="col-form-label">
                                  <select
                                    type="number"
                                    name="age"
                                    {...register("age")}
                                    onChange={handleChange}
                                    style={{
                                      backgroundColor: "#1C6455",
                                      border: "none",
                                      color: "rgb(196, 212, 144)",
                                    }}
                                  >
                                    <option value="" selected>
                                      {" "}
                                      Age
                                    </option>
                                    <option value={age}>21</option>
                                    <option value="">22</option>
                                    <option value="">23</option>
                                    <option value="">24</option>
                                    <option value="">25</option>
                                  </select>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-8 col-lg-6 text-left">
                              <div className="inputContainer form-group">
                                <label
                                  htmlFor="gender"
                                  className="col-form-label"
                                >
                                  <select
                                    type="text"
                                    name="gender"
                                    {...register("gender")}
                                    onChange={handleChange}
                                    style={{
                                      backgroundColor: "#1C6455",
                                      border: "none",
                                      color: "rgb(196, 212, 144)",
                                    }}
                                  >
                                    <option value="" selected>
                                      {" "}
                                      Gender
                                    </option>
                                    <option value="">Male</option>
                                    <option value="">Female</option>
                                  </select>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-8 col-lg-6 text-left">
                              <div className="inputContainer form-group">
                                <label
                                  htmlFor="institution"
                                  className="col-form-label"
                                >
                                  <input
                                    type="text"
                                    name="institution"
                                    placeholder="Institution/Organisation*"
                                    {...register("institution")}
                                    style={{
                                      backgroundColor: "#1C6455",
                                      border: "none",
                                      outline: "none",
                                      color: "rgb(196, 212, 144)",
                                    }}
                                  />
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-8 col-lg-6 text-left">
                              <div className="inputContainer form-group">
                                <label
                                  htmlFor="city"
                                  className="col-form-label"
                                >
                                  <input
                                    type="text"
                                    name="city"
                                    placeholder="City*"
                                    {...register("city")}
                                    style={{
                                      backgroundColor: "#1C6455",
                                      border: "none",
                                      outline: "none",
                                      color: "rgb(196, 212, 144)",
                                    }}
                                  />
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-8 col-lg-6 text-left">
                              <div className="inputContainer form-group">
                                <label
                                  htmlFor="email"
                                  className="col-form-label"
                                >
                                  <input
                                    type="email"
                                    name="email"
                                    placeholder="Email Id*"
                                    {...register("email")}
                                    style={{
                                      backgroundColor: "#1C6455",
                                      border: "none",
                                      outline: "none",
                                      color: "rgb(196, 212, 144)",
                                    }}
                                  />
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-8 col-lg-6 text-left">
                              <div className="inputContainer form-group">
                                <label
                                  htmlFor="phone"
                                  className="col-form-label"
                                >
                                  <input
                                    type="number"
                                    name="phone"
                                    placeholder="Mobile Number*"
                                    {...register("phone")}
                                    style={{
                                      backgroundColor: "#1C6455",
                                      border: "none",
                                      outline: "none",
                                      color: "rgb(196, 212, 144)",
                                    }}
                                  />
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-8 col-lg-4 text-left">
                              <label htmlFor="checkbox_attending">
                                <input
                                  type="checkbox"
                                  name="checkbox"
                                  value={checked.toString()}
                                  onChange={handleChange}
                                  {...register("checkbox")}
                                />
                                Attending in person
                              </label>
                            </div>
                            <div className="col-sm-12 col-md-8 col-lg-8 text-left">
                              <label htmlFor="existing_heartfulness">
                                <input
                                  type="checkbox"
                                  name="checkbox"
                                  value={checked.toString()}
                                  onChange={handleChange}
                                  {...register("checkbox")}
                                />
                                Are you an existing Heartfulness Mediatator ?
                              </label>
                            </div>
                          </div>
                          <div className="form-group">
                            <button
                              type="submit"
                              className="apply text-center"
                              style={{
                                width: "200px",
                                height: "57px",
                                borderRadius: "62px",
                                marginBottom: "50px",
                                marginTop: "50px",
                                backgroundColor: "#46817e",
                                border: "none",
                                outline: "none",
                                color: "#a1c5cb",
                              }}
                            >
                              Register
                            </button>
                          </div>
                        </div>
                        <div
                          className="col-sm-12 col-md-4 col-lg-4 text-right"
                          style={{ display: "block", width: "300px" }}
                        >
                          <div className="inputContainer form-group">
                            <label
                              htmlFor="story_area"
                              className="col-form-label"
                            >
                              <textarea
                                className="form-control"
                                placeholder="Share your kindness story"
                                id="story_area"
                                rows="7"
                                name="story_area"
                                {...register("story_area")}
                                style={{
                                  backgroundColor: "#1C6455",
                                  color: "rgb(196, 212, 144)",
                                }}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </form>
                  </>
                ) : (
                  <h3 style={{ textAlign: "center", marginTop: "100px" }}>
                    Thank you for registering!
                  </h3>
                )}
              </ReactModal>
            </div>
            {/* </div>
                    </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default ApplyNow;
