import React from "react";
// import "font-awesome/css/font-awesome.min.css";
import Accordion from "../Accordion";

function FAQ() {
  return (
    <>
      <section className="faq_form">
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-12 col-lg-12 text-center">
            <h2 className="title" style={{ color: " #202225" }}>
              faq
            </h2>
          </div>
        </div>
        <div className="row justify-content-center my-5 faq_qn">
          <div className="col-sm-12 col-md-12 col-lg-12 text-center">
            <Accordion />
          </div>
        </div>
      </section>
    </>
  );
}

export default FAQ;
