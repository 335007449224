import React from "react";
import AccordionData from "./AccordionData";

function AccordionList() {
  const [toggle, setToggle] = React.useState(1);

  const handleToggle = (id) => {
    if (toggle === id) {
      setToggle(null);
      return false;
    }
    setToggle(id);
    return true;
  };

  return (
    <>
      {AccordionData &&
        AccordionData.map((value) => {
          const { id, qn, ans } = value;
          return (
            <div
              className="card my-3 col-lg-12"
              key={id}
              style={{ borderRadius: "9px" }}
            >
              <div
                className="card-header"
                role="presentation"
                onClick={() => handleToggle(id)}
                style={{
                  cursor: "pointer",
                  boxShadow: "0px 1px 7px #033A7D61",
                }}
              >
                <b>
                  <span className="ques" style={{ float: "left" }}>
                    {qn}
                  </span>
                  <span style={{ float: "right" }}>
                    {id === toggle ? "-" : "+"}
                  </span>
                </b>
              </div>
              {id === toggle ? (
                <div
                  className="card-body"
                  style={{
                    boxShadow: "0px 1px 7px #033A7D61",
                    textAlign: "left",
                  }}
                >
                  {ans}
                </div>
              ) : null}
            </div>
          );
        })}
    </>
  );
}

export default AccordionList;
