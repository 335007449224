import React from "react";
import AccordionList from "./AccordionList";

function Accordion() {
  return (
    <>
      <div className="container">
        <div className="row justify-content-center my-5">
          <div className="col-sm-12 col-md-10 col-lg-12 mb-5 text-center">
            <AccordionList />
          </div>
        </div>
      </div>
    </>
  );
}

export default Accordion;
