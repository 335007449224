import React from "react";
import { Tab, Tabs } from "react-bootstrap";
// import { StaticImage } from 'gatsby-plugin-image';
// import concertImage from "../../images/concert.jpg";
// import {authorImg} from "../../images/img2.webp"
// import {slider2} from "../../images/slider2.webp"
import Slider from "react-slick";
import slider3 from "../../images/slider3.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Get_Agenda() {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    draggable: true,
    focusOnSelect: true,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  //   const [key, setKey] = useState('day1');

  return (
    <>
      <section className="get_agenda">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-12 text-center">
              <h2 className="title" style={{ color: " #202225" }}>
                agenda
              </h2>
            </div>
          </div>
          <div className="row justify-content-center my-5 mx-3">
            <div className="col-sm-12 col-md-10 col-lg-4 text-center">
              <div className="agenda_date" style={{ width: "80%" }}>
                <h3>JUNE 17th</h3>
                <p>Friday</p>
              </div>
            </div>
            <div className="col-sm-12 col-md-10 col-lg-4 text-center">
              <div className="agenda_date" style={{ width: "80%" }}>
                <h3>JUNE 18th</h3>
                <p>Saturday</p>
              </div>
            </div>
            <div className="col-sm-12 col-md-10 col-lg-4 text-center">
              <div className="agenda_date" style={{ width: "80%" }}>
                <h3>JUNE 19th</h3>
              </div>
            </div>
          </div>

          <Tabs
            defaultActiveKey="agenda_day1"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="agenda_day1" title="Day 1" className="agenda_day">
              <div className="row justify-content-center my-5" id="agenda_day1">
                <div className="col-sm-12 col-md-12 col-lg-10 text-center concert_content">
                  <div className="row justify-content-center m-3">
                    <h4 className="day my-3">DAY 1</h4>
                    <h1>EXPERIENTIAL</h1>
                    <div className="col-sm-12 col-md-12 col-lg-6 left-content">
                      <p>
                        Yoga + meditation
                        <br />
                        Musical Interlude
                        <br />
                        Opening
                        <br />
                        SEEK Workshop
                        <br />
                        Kanha Tour
                        <br />
                        Networking Plantation
                      </p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 text-center">
                      <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-12 col-lg-4 mx-3 text-center rounded-circle image" />
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-12 col-lg-4 mx-3 text-center author_information">
                          <h5>Kamlesh D Patel</h5>
                          <p>
                            the Global Guide of
                            <br />
                            The Heartfulness Institute
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="agenda_day2" title="Day 2" className="agenda_day">
              <div className="row justify-content-center my-5" id="agenda_day2">
                <div className="col-sm-12 col-md-12 col-lg-10 text-center concert_content">
                  <div className="row justify-content-center m-3">
                    <h4 className="day my-3">DAY 2</h4>
                    <h1>CEREBRAL</h1>
                    <div className="col-sm-12 col-md-12 col-lg-6 left-content">
                      <p>
                        Keynote
                        <br />
                        Recap
                        <br />
                        Yoga + Meditation
                        <br />
                        Green & Blue Innovation Breakouts
                        <br />
                        TAGe: Science of Kindness
                        <br />
                        Heartful Communication
                      </p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 text-center">
                      <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-12 col-lg-4 mx-3 text-center rounded-circle image" />
                      </div>
                      <div className="row justify-content-center my-1">
                        <div className="col-sm-12 col-md-12 col-lg-4 mx-3 text-center author_information">
                          <h5>Anantha Duraiappah</h5>
                          <p>Director,</p>
                          <p>UNESCO MGIEP</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="agenda_day3" title="Day 3" className="agenda_day">
              <div className="row justify-content-center my-5" id="agenda_day3">
                <div className="col-sm-12 col-md-12 col-lg-10 text-center concert_content">
                  <div className="row justify-content-center m-3">
                    <h4 className="day my-3">DAY 3</h4>
                    <h1>HEARTFUL</h1>
                    <div className="col-sm-12 col-md-12 col-lg-6 left-content">
                      <p>
                        Keynote
                        <br />
                        Youth Project Showcase
                        <br />
                        Meditation
                        <br />
                        Panel
                        <br />
                        Breakouts
                        <br />
                        Kindness Concert
                      </p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 text-center">
                      <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-12 col-lg-4 mx-3 text-center rounded-circle image" />
                      </div>
                      <div className="row justify-content-center my-1">
                        <div className="col-sm-12 col-md-12 col-lg-4 mx-3 text-center author_information">
                          <h5>Tejaswini Manogna</h5>
                          <p>
                            Medical Doctor, Indian Model,
                            <br />
                            Miss India Earth 2019
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
          <div className="row justify-content-center joining_info">
            <div className="col-sm-12 col-md-12 col-lg-12 text-center">
              <h3>
                Join us In the Oasis of Kindness &#38; World&apos;s largest
                meditation centre -
              </h3>
              <h3>Kanha Shanti Vanam, Hyderabad</h3>
            </div>
          </div>
          <div
            className="row justify-content-center"
            style={{ margin: "50px 0px" }}
          >
            <Slider {...settings}>
              <div className="slick-list">
                {/* <div className='col-sm-12 col-md-4 col-lg-3 mx-5 text-center'> */}
                <img className="px-5" src={slider3} alt="conference1" />
                {/* </div> */}
              </div>
              <div className="slick-list">
                {/* <div className='col-sm-12 col-md-4 col-lg-4 text-center' > */}
                <img className="px-5" src={slider3} alt="conference2" />
                {/* </div> */}
              </div>
              <div className="slick-list">
                {/* <div className='col-sm-12 col-md-4 col-lg-4 text-center' > */}
                <img className="px-5" src={slider3} alt="conference3" />
                {/* </div> */}
              </div>
              <div className="slick-list">
                {/* <div className='col-sm-12 col-md-4 col-lg-4 text-center'> */}
                <img className="px-5" src={slider3} alt="conference4" />
                {/* </div> */}
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
}

export default Get_Agenda;
